const Footer = () => {
  return (
    <>
      <div className="Footer">
        <div className="Content">
          <h6>
            Build By{" "}
            <span>
              <a
                href="
https://www.facebook.com/WebixDevelops"
              >
                Webix
              </a>
            </span>
          </h6>
          <p>
            Copyright © 2024 Khayrkm by{" "}
            <span>
              <a
                href="
https://www.facebook.com/WebixDevelops"
              >
                Webix
              </a>
            </span>
            . All Rights Reserved.
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
