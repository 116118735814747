const LoadApi =()=> {

    return (
        <>
         <div className="loader"></div>
        </>
    )
    
    }
    
    
    export default LoadApi