

const ErrorApi =()=> {

return (
    <>
    <div className="ErrorApi">
        <h3>Please Check internt</h3>
    </div>
    </>
)

}



export default ErrorApi