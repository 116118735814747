const ErrorPage  = ()=> {
return (
    <>
    <div class="error-container">
        <h1>Error 404</h1>
        <p>Page Not Found</p>
        <p>Sorry, the page you are looking for does not exist.</p>
    </div>
    </>
)
}

export default ErrorPage